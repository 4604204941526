
import getPageQuery from '~/queries/getPageQuery';

export default {
  // Note: fetch is called on server-side when rendering the route,
  // and on client-side when navigating.
  async asyncData({ store, error, route, i18n, $sanity }) {
    const { isPreviewMode, document } = store.state.preview;
    let page = null;
    const { locale } = i18n;
    const {
      params: { pathMatch }
    } = route;

    // this.page will be hydrated with draft data taken from the store
    // and fetched in the middleware 'previewMode'
    if (isPreviewMode) {
      page = document;
      return { page };
    }

    try {
      const pageQuery = getPageQuery(pathMatch, locale);
      page = await $sanity.fetch(pageQuery);
      const isPageEmpty = page == null || Object.keys(page).length === 0;

      if (isPageEmpty) {
        error({
          statusCode: 404,
          message: 'Page not found'
        });
      }

      if (page._type === 'blog') {
        store.commit('page/setBlogPostId', page._id);
        store.commit('page/setIsBlogPage', true);
        store.commit('page/setCategories', page.postCategories);
      } else {
        // clear out stores values
      }

      store.commit('page/setTranslations', page.translations);

      return { page };
    } catch (err) {
      if (process.server) {
        error({
          statusCode: 404,
          message: 'Page not found'
        });
      }
    }
  },
  data() {
    return {
      page: null
    };
  },
  head() {
    // generate meta tags only when not in preview
    const { isPreviewMode } = this.$store.state.preview;
    return !isPreviewMode && this.generatePageMetaTags(this.page);
  },
  watch: {
    // eslint-disable-next-line
    '$fetchState.pending': function (val) {
      this.$store.commit('ui/toggleLoader', val);
    }
  },
  mounted() {
    this.initLocalStorage();
  }
};
