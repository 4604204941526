import { groq } from '@nuxtjs/sanity';
/**
 * In this query, we retrieve a page and its translations
 */

export default function getPageQuery(slug, locale) {
  // TODO: make this function a plugin for Vue and use defaultLang
  // Query for all pages
  let defaultQuery = `(_type=="page" || _type=="blog") &&
  live==true &&
  url.fullPath=="${slug}" &&
  dateTime(now()) >= dateTime(publicationDate) &&
  __i18n_lang=="${locale}_CH" &&
  !(_id in path("drafts.**"))`;

  // Query for the homepage. We need to do that because homepage as no slug by default
  if (slug === '') {
    defaultQuery = `_type=="page" &&
    live==true &&
    home==true &&
    __i18n_lang=="${locale}_CH" &&
    !(_id in path("drafts.**"))`;
  }

  return groq`*[${defaultQuery}][0] {
  ...,
  _type == "blog" => {
    "author": author->{name},
    "postCategories": postCategories[]->{entry, _id}
  },
  _type,
  meta {
    noFollow,
    noIndex,
    title,
    description,
    "image": image.refImage->{"data": upload.asset->{url}}
  },
  parent->{slug},
  content{
    ...,
    sections[]{
      ...,
    }
  },
  !(_id match "__i18n_") => {
    "translations": {
      "de": {
        home,
        url
      },
      "fr":__i18n_refs[_key == "fr_CH"][0]->{
        home,
        url
      },
      "it":__i18n_refs[_key == "it_CH"][0]->{
        home,
        url
      },
    }
  },
  (_id match "*__i18n_*") => {
    "translations":
    *[references(^._id) && __i18n_lang =="de_CH"]{
      "de":{
        home,
        url,
        __i18n_lang,
      },
      "fr":(__i18n_refs[]->{
        home,
        url,
        __i18n_lang,
      })[__i18n_lang=="fr_CH"][0], 
      "it":(__i18n_refs[]->{
        home,
        url,
        __i18n_lang,
      })[__i18n_lang=="it_CH"][0], 
    }[0]
  }
}`;
}
